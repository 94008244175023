import React from "react"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"

/**
 * A box section used in colums
 * @param {children}
 * @param {string} title string
 * @param {object} image settings for the image (src, alt)
 */

const MediaBox = ({ image, title, children }) => {
  return (
    <div
      className={classNames(
        "column is-3-fullhd is-4-desktop px-2 pb-4",
        styles["mediaBox"]
      )}
    >
      <img
        src={image?.src}
        alt={image?.alt}
        className={styles["mediaBox__image"]}
        width={image?.width || "50%"}
      />

      <h3 className={styles["mediaBox__title"]}>{title}</h3>

      <p className="has-text-left">{children}</p>
    </div>
  )
}

export default MediaBox
